import React, { useEffect } from "react";
import Script from "next/script";
import useUser from "../hooks/useUser";
import hmacSHA256 from "crypto-js/hmac-sha256";
import encHex from "crypto-js/enc-hex";
import { useRouter } from "next/router";

const Notifuse = () => {
  const user = useUser();
  const { pathname, locale } = useRouter();
  return (
    <>
      <Script
        src="https://notifuse.com/widget/widget2.min.js"
        onLoad={useEffect(() => {
          if (window["Notifuse"]) {
            //console.log("notifuse loaded", user.uid);
            locale === "fr"
              ? window["Notifuse"].init({
                  projectId: "themecloud_themecloud",
                  userId: `${user?.uid}`,
                  userHmac: `${encHex.stringify(
                    hmacSHA256(
                      user?.uid,
                      process.env.NEXT_PUBLIC_NOTIFUSE_SECRET
                    )
                  )}`,
                  classNames: {
                    container: "my-container-class",
                    itemContent: "my-item-content",
                    title: "my-title",
                    item: "my-item",
                    itemTime: "my-item-time",
                    itemDescription: "my-item-description",
                    header: "my-header",
                  },
                  onClickMessage: (message) => {
                    console.log("got click on message", message);
                    switch (message.notificationId) {
                      default:
                        //console.log("notification not implemented", notificationId);
                        console.log("notification not implemented");
                    }
                  },
                  bellSelector: "#notifuse-bell",
                  text: {
                    markAllAsRead: "Tout marquer comme lu",
                    empty: "Aucune notification...",
                  },
                })
              : window["Notifuse"].init({
                  projectId: "themecloud_themecloud",
                  userId: `${user?.uid}`,
                  userHmac: `${encHex.stringify(
                    hmacSHA256(
                      user?.uid,
                      process.env.NEXT_PUBLIC_NOTIFUSE_SECRET
                    )
                  )}`,
                  classNames: {
                    container: "my-container-class",
                    itemContent: "my-item-content",
                    title: "my-title",
                    item: "my-item",
                    itemTime: "my-item-time",
                    itemDescription: "my-item-description",
                    header: "my-header",
                  },
                  onClickMessage: (message) => {
                    console.log("click on message", message);
                    switch (message.notificationId) {
                      default:
                      //console.log("notification not implemented", notificationId);
                      //console.log("notification not implemented");
                    }
                  },
                  bellSelector: "#notifuse-bell",
                });
          }
        }, [user?.uid, locale, pathname])}
      />
    </>
  );
};
export default Notifuse;
