import React, { useState } from "react";
import { Box, IconButton, MenuItem, Menu } from "@mui/material";
import { useRouter } from "next/router";

export default function LocaleSwitcher() {
  const { locale, locales, pathname, asPath, query, push } = useRouter();
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const setLanguage = async (l) => {
    setAnchorEl(null);
    await push({ pathname, query }, asPath, {
      locale: l,
    });
    // Router.reload();
  };

  return (
    <>
      <IconButton
        aria-controls="simple-menu"
        aria-haspopup="true"
        color="primary"
        onClick={openMenu}
        sx={{
          borderRadius: "50%",
          marginLeft: "-8px",

          lineHeight: "16px",
          backgroundColor: "transparent",
        }}
      >
        <Box
          sx={{
            width: "1em",
            height: "1em",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            minWidth: "21px",
            minHeight: "21px",
            borderRadius: "50%",
            fontSize: "12px",
            fontWeight: "regular",
            background: "#00448b",
            color: "white",
          }}
        >
          {locale}
        </Box>
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {locales.map((l) => (
          <MenuItem key={l} onClick={() => setLanguage(l)}>
            {l}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
