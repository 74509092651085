import React, { useEffect, useState } from "react";
import { auth } from "lib/firebase";
import { signInWithCustomToken } from "firebase/auth";
import ButtonLink from "components/ButtonLink";
import { useRouter } from "next/router";
import {
  AppBar,
  Autocomplete,
  Box,
  Button,
  IconButton,
  Typography,
  Menu,
  MenuItem,
  TextField,
  Toolbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircle";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import makeStyles from "@mui/styles/makeStyles";
import useTranslation from "next-translate/useTranslation";
import Image from "next/image";
import Link from "next/link";
import LocaleSwitcher from "./localeswitcher";
import ProfileMenu from "./profilemenu";
import useNotify from "hooks/useNotify";
import useUser from "../hooks/useUser";
import { useAppContext } from "../contexts/BrandContext";
import Crisp from "components/crisp";
import Notifuse from "components/notifuse";
import Cookies from "universal-cookie";
import CircleNotificationsIcon from "@mui/icons-material/CircleNotifications";
import RedeemIcon from "@mui/icons-material/Redeem";
import useAuthenticatedQuery from "../hooks/useAuthenticatedQuery";
import SearchIcon from "@mui/icons-material/Search";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ErrorIcon from "@mui/icons-material/Error";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import CodeIcon from "@mui/icons-material/Code";

export default function Header() {
  const classes = useStyles();
  const { notify } = useNotify();
  const { t } = useTranslation("common");
  const { push } = useRouter();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [brand, setBrand] = React.useState(null);
  const [host, setHost] = React.useState(null);
  const [value, setValue] = useState(null);

  const mycontext = useAppContext();
  const { pathname } = useRouter();
  const loginPage =
    pathname === "/login"
      ? true
      : pathname === "/register"
      ? true
      : pathname === "/organizations/new"
      ? true
      : pathname === "/organizations/newpro"
      ? true
      : pathname === "/redirect"
      ? true
      : pathname === "/preview"
      ? true
      : false;
  const user = useUser();
  const { data } = useAuthenticatedQuery(`/api/organizations`, {
    staleTime: 5000,
    retry: true,
    enabled: !!user?.accessToken,
  });
  useEffect(() => {
    // Call api only if user contains data.
    if (mycontext != {}) {
      setBrand(user?.isPartner ? "faaasterpro" : mycontext.brand);
      setHost(mycontext.hostName);
    }
  }, [mycontext, user?.isPartner, user?.accessToken, loginPage, data]);

  const xAppList = user?.xAppList ?? [];
  const AppList = xAppList
    ? xAppList
        .filter((app) => app.runningStatus != "deleted")
        .map((app) => {
          const label = app.name + " - " + app.url;
          return {
            label,
            name: app.name,
            url: app.url,
            id: app.id,
            runningStatus: app.runningStatus,
            uptimeStatus: app.uptimeStatus,
            plan: app.plan,
          };
        })
    : [];

  // const [hostName, setHostName] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (route) => {
    setAnchorEl(null);
    if (route.length > 0) {
      push(`/admin/${route}`);
    }
  };
  let payoutsTotal = 0;
  data?.map((org) => {
    payoutsTotal += org.totalPayout;
  });

  const exitIMP = async () => {
    try {
      const cookies = new Cookies();
      cookies.remove("impersonateToken", { path: "/" });
      await signInWithCustomToken(auth, user?.impersonateToken);
    } catch (err) {
      notify(t("token-expired"), "error");
      push("/logout");
    }
  };

  const handleOnChange = async (event, newValue) => {
    if (newValue) {
      setValue(newValue);
      // Redirect to the selected site's management page
      await push(`/applications/${newValue.id}/instances/default/0`);
      console.log("PUSHED");
      setValue(null);
    }

    // Reset the input value to keep the text field blank
    setValue(null);
  };

  return (
    <>
      <AppBar
        position="static"
        color="background"
        sx={{
          boxShadow: "none",
          position: loginPage ? "absolute" : "",
          backgroundImage: "url(/reflet.png)",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "0% 23%",
          background: loginPage ? "transparent" : "",
          zIndex: "99",
        }}
      >
        <Crisp />
        {!loginPage &&
          user?.accessToken &&
          user?.uid &&
          host &&
          !host?.includes("localhost") && (
            <>
              <Notifuse />
            </>
          )}
        <Toolbar>
          <Box
            sx={{
              marginRight: "auto!important",
              display: "flex",
              alignItems: "flex-start",
              width: "180px",
              flex: "1",
            }}
          >
            <Link href="/" passHref={true}>
              <Box
                width={brand === "faaasterpro" ? "180px" : "130px"}
                height="60px"
                position="relative"
              >
                <Image
                  src={
                    brand === "faaasterpro"
                      ? "/faaaster-pro.png"
                      : "/faaaster-blue.png"
                  }
                  alt="Faaaster logo"
                  fill={true}
                  priority
                  style={{ objectFit: "contain" }}
                />
              </Box>
            </Link>
          </Box>
          <Box display="flex" alignItems="center">
            {!loginPage && user?.accessToken && (
              <Box>
                <Autocomplete
                  id="combo-box-demo"
                  onChange={handleOnChange}
                  value={value}
                  // open={true}
                  options={AppList}
                  getOptionLabel={(option) => option.label}
                  renderOption={(props, option) => (
                    <Box {...props}>
                      <Box>
                        <Box
                          display="flex"
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "14px !important",
                              my: 0.5,
                              mr: 0.7,
                              "&.iconbox": {
                                my: 0.5,
                                mr: 0.7,
                              },
                            },
                            "& .devicon.iconbox": {
                              my: 0.5,
                              mr: 0.7,
                            },
                            "& .devicon:before": {
                              width: "12px",
                              height: "12px !important",
                              border: "1px solid #acacac !important",
                              left: "1px",
                            },
                          }}
                        >
                          {option?.runningStatus === "ready" &&
                          !option?.plan.includes("dev") &&
                          option?.uptimeStatus !== "down" ? (
                            <CheckCircleOutlinedIcon
                              color="secondary"
                              fontSize="small"
                              className="iconbox"
                            />
                          ) : option?.runningStatus === "ready" &&
                            option?.uptimeStatus === "down" ? (
                            <ErrorIcon
                              color="error"
                              fontSize="small"
                              className="iconbox"
                            />
                          ) : null}
                          {option?.runningStatus === "ready" &&
                            option?.plan.includes("dev") &&
                            option?.uptimeStatus !== "down" && (
                              <Box
                                className="devicon"
                                sx={{
                                  position: "relative",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",

                                  "&:before": {
                                    content: "''",
                                    display: "block",
                                    position: "absolute",
                                    width: "18px",
                                    height: "18px !important",
                                    borderRadius: "100%",
                                    border: "2px solid #acacac",
                                    color: "#acacac",
                                  },
                                }}
                              >
                                <CodeIcon
                                  color="#acacac"
                                  fontSize="small"
                                  className="iconbox"
                                  sx={{
                                    color: "#acacac",
                                    padding: "3px",
                                  }}
                                />
                              </Box>
                            )}
                          {option?.runningStatus === "stopped" && (
                            <PauseCircleOutlineOutlinedIcon
                              className="iconbox"
                              color="warning"
                              fontSize="small"
                            />
                          )}
                          {(option?.runningStatus === "archived" ||
                            option?.runningStatus === "deleted") && (
                            <CancelOutlinedIcon
                              color="error"
                              fontSize="small"
                              className="iconbox"
                            />
                          )}
                          <Typography>{option.name}</Typography>
                        </Box>
                        <Box display="flex" alignItem="center">
                          <Typography
                            variant="caption"
                            sx={{ lineHeight: "1" }}
                          >
                            {option.url}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                  componentsProps={{
                    paper: {
                      sx: {
                        width: 400,
                        "& .MuiAutocomplete-option": {
                          fontSize: "12px",
                        },
                      },
                    },
                  }}
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      label=""
                      sx={{
                        "& .MuiInputBase-root": {
                          padding: "2px !important",
                          paddingRight: "6px !important",
                          backgroundColor: "#ffffff75",
                        },
                        "& fieldset": {
                          borderColor: "#dee5ed !important",
                        },
                      }}
                      InputProps={{
                        ...params.InputProps,
                        type: "search",
                        endAdornment: (
                          <SearchIcon
                            color="primary"
                            sx={{ marginRight: 0.5, fontSize: "18px" }}
                          />
                        ),
                      }}
                    />
                  )}
                />
              </Box>
            )}
            {!loginPage && !user?.impersonateToken && user.role === "admin" && (
              <>
                <Button
                  startIcon={<LockIcon sx={{ fontSize: "12px" }} />}
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                  sx={{ marginLeft: 2 }}
                >
                  {t("admin")}
                </Button>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  <MenuItem onClick={() => handleClose("domains")}>
                    Domains
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("applications")}>
                    Applications
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("users")}>
                    Users
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("unpaid")}>
                    Unpaid
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("cancelled")}>
                    Cancelled
                  </MenuItem>
                  <MenuItem onClick={() => handleClose("pros")}>Pros</MenuItem>
                </Menu>
              </>
            )}

            {!loginPage && user?.impersonateToken && (
              <Button
                onClick={exitIMP}
                startIcon={<LockOpenIcon sx={{ fontSize: "12px" }} />}
                className={classes.back}
              >
                {t("exit-imp")}
              </Button>
            )}

            {!loginPage && user?.accessToken && (
              <ButtonLink
                color="primary"
                href="/applications"
                className={classes.back}
              >
                {t("sites")}
              </ButtonLink>
            )}

            {!loginPage && user?.accessToken && (
              <ButtonLink
                color="primary"
                href="/domains"
                className={classes.back}
              >
                {t("domains")}
              </ButtonLink>
            )}
            {!loginPage && user?.accessToken && user?.isPartner && (
              <ButtonLink
                color="primary"
                href="/central"
                className={classes.back}
              >
                {t("central")}
              </ButtonLink>
            )}
            {!loginPage && user?.accessToken && (
              <ButtonLink
                sx={{
                  fontSize: "14px",
                  border: "1px solid #cbd8e1",
                  padding: "4px 13px",
                  backgroundColor: "#f1f0f8",
                  "&:hover": {
                    backgroundColor: "#00458b0a",
                  },
                }}
                size="medium"
                href={`/applications/cart/0?path=${pathname}`}
                className={classes.back}
                variant="outlined"
                startIcon={<AddIcon sx={{ height: "18px" }} />}
              >
                {t("new-site")}
              </ButtonLink>
            )}
            {!loginPage &&
              user?.promoter &&
              Object.keys(user?.promoter).length == 1 && (
                <Button
                  variant="outlined"
                  size="medium"
                  sx={{
                    borderRadius: "4px",
                    fontSize: "14px",
                    border: "1px solid #cbd8e1",
                    padding: "4px 13px",
                    backgroundColor: "#f1f0f8",
                    marginLeft: 2,
                    "& .MuiButton-startIcon": {
                      marginRight: payoutsTotal > 0 ? "8px" : "0px",
                      marginLeft: payoutsTotal > 0 ? "-4px" : "0px",
                    },
                  }}
                  startIcon={<RedeemIcon />}
                  href={`/organizations/${Object.keys(user?.promoter)[0]}/4`}
                >
                  {payoutsTotal > 0 && " " + payoutsTotal / 100 + "€"}
                </Button>
              )}
            {!loginPage && user?.accessToken && (
              <Box
                sx={{
                  padding: "8px",
                  marginLeft: "8px",
                  borderRadius: "50%",
                  "&:hover": {
                    backgroundColor: "#3fd2c624",
                  },
                  "&:after": {
                    content: '""',
                    overflow: "hidden",
                    pointerEvents: "none",
                    position: "absolute",
                    zIndex: 0,
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    borderRadius: "inherit",
                  },
                }}
              >
                <Box
                  className="logo"
                  sx={{
                    width: "1em",
                    height: "1em",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    minWidth: "20px",
                    minHeight: "20px",
                    borderRadius: "50%",
                    fontSize: "14px",
                    fontWeight: "bold",
                    background: "#00448b",
                    color: "white",
                    cursor: "pointer",
                    fontFamily: "math, Filson Pro, sans-serif",
                  }}
                >
                  i
                </Box>
              </Box>
            )}
            {/* <IconButton size="medium" sx={{ ml: 1 }}>
              <InfoIcon fontSize="inherit" color="primary" />
            </IconButton> */}
            {!loginPage && user?.accessToken && (
              <IconButton
                id="notifuse-bell"
                size="medium"
                sx={{
                  maxHeight: "40px",
                  "& div[class^='main-footer']": { display: "none" },

                  fontSize: "12px",
                  marginLeft: "-8px",
                  backgroundColor: "transparent",
                  "& #notifuse-dropdown": {
                    position: "absolute",
                    top: "9px",
                    right: 0,
                  },
                  "& .my-container-class": {
                    all: "unset",
                    position: "absolute",
                    top: 0,
                    right: 0,
                    zIndex: 1000,
                    fontSize: "14px",
                    margin: "15px 10px 0 0",
                    background: "#fff",
                    boxShadow: "2px 2px 30px 0 rgba(0,0,0,.15)",
                    borderRadius: "4px",
                    width: "430px",
                    lineHeight: "1.6em",
                    textAlign: "left",
                    color: "initial",
                    cursor: "initial",
                  },
                  "& .my-item-content": {
                    padding: "15px 30px 15px 20px",
                  },
                  "& .my-item-content .my-item-description": {
                    fontSize: "12px",
                  },
                  "& .my-item-content .my-item-description > b > span": {
                    marginRight: "2px",
                    fontSize: "9px",
                  },
                  "& .my-item-content .my-item-description > div": {
                    padding: "0",
                  },
                  "& .my-header > span > span": {
                    fontSize: "13px",
                  },
                  "& .my-header span + span ": {
                    color: "#00458b",
                    fontWeight: "bold",
                    fontSize: "18px",
                    fontFamily: "Filson Pro",
                    marginLeft: "5px",
                  },
                }}
                color="primary"
              >
                <CircleNotificationsIcon />
              </IconButton>
            )}
            <LocaleSwitcher />
            {!loginPage && user?.accessToken && <ProfileMenu data={data} />}
          </Box>
        </Toolbar>
      </AppBar>
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
  back: {
    marginLeft: theme.spacing(2),
  },
}));
