import React, { useCallback, useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { onAuthStateChanged, signOut } from "firebase/auth";
import { auth } from "lib/firebase";
import UserContext from "contexts/UserContext";
import Cookies from "universal-cookie";

export default function UserProvider({ children }) {
  const [user, setUser] = useState(null);
  const [loadingUser, setLoadingUser] = useState(true);
  const [loadingProfile, setLoadingProfile] = useState(false);
  const [logoutLoading, setLogoutLoading] = useState(false);
  const cookies = new Cookies();

  const { asPath, pathname, query, push } = useRouter();
  let timeoutId = useRef();
  let tokenId = useRef();
  // const [checkLoginInterval, setCheckLoginInterval] = useState(null);
  // console.log("user", user);
  // console.log("checkLoginInterval", checkLoginInterval);

  // These paths do not have login enforced
  const isAuthPage = !asPath
    ? undefined
    : !pathname
    ? undefined
    : [
        "/login",
        "/login_token",
        "/logout",
        "/register",
        "/useraction",
        "/organizations/new",
        "/organizations/newpro",
        "/sso",
        "/authorize",
        "/preview",
        "/domains/search",
      ].includes(pathname)
    ? true
    : asPath.toString().includes("/applications/cart/0?status=subscribe");
  // Logout user function
  const logoutUser = async () => {
    setUser(null);
    setLogoutLoading(true);
    clearTimeout(timeoutId);
    await signOut(auth);

    console.log("Redirecting to logout");
    await push({
      pathname: "/login",
      query: { session: "expired" },
    });
    setLogoutLoading(false);
  };
  const updateProfile = useCallback(async (newUser) => {
    if (!loadingProfile) {
      setLoadingProfile(true);
      const impersonateToken =
        cookies.get("impersonateToken") === "undefined"
          ? ""
          : cookies.get("impersonateToken");
      // console.log("impersonateToken", impersonateToken);
      const { uid, displayName, email, accessToken, preferences, phone, role } =
        newUser;
      tokenId.current = accessToken;
      if (tokenId.current) {
        const userProfile = await fetch("/api/profile", {
          headers: {
            Authorization: `Bearer ${tokenId.current}`,
            "Content-Type": "application/json",
          },
        }).then((res) => res.json());
        if (!userProfile?.preferences?.timeZone) {
          await fetch(`/api/profile`, {
            method: "PUT",
            body: JSON.stringify({
              timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            }),
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${user?.accessToken}`,
            },
          });
        }
        // console.log("userProfile", userProfile);
        setUser({
          uid,
          displayName,
          email,
          accessToken,
          preferences: {
            ...preferences,
            timeZone:
              preferences?.timeZone ||
              Intl.DateTimeFormat().resolvedOptions().timeZone,
          },
          phone,
          role,
          impersonateToken: impersonateToken,
          ...userProfile,
        });
        setLoadingProfile(false);

        // Manage email verification
        if (!auth?.currentUser?.emailVerified && userProfile?.createdAt) {
          try {
            const today = Date.now();
            const delta = Math.abs(today - Date.parse(userProfile?.createdAt));
            // Logout user if created more than 24H and email not verified
            // console.log("unverified user email");
            if (
              userProfile.restricted == true ||
              userProfile?.preferences?.timeZone.includes("Africa")
            ) {
              push({
                pathname: "/useraction",
                query: { status: "verifyEmail" },
              });
              return;
            } else if (delta > 24 * 60 * 60 * 1000) {
              push({
                pathname: "/useraction",
                query: { status: "verifyEmail" },
              });
              return;
            }
          } catch {
            return;
          }
        }
        // Sync InfrAPIs user
        fetch(`/api/users/sync`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${tokenId.current}`,
          },
          body: JSON.stringify({
            userId: newUser.uid,
          }),
        });
      }
    }
  }, []);
  useEffect(() => {
    const unsubscriber = onAuthStateChanged(auth, async (authUser) => {
      // console.log("Auth state changed");
      try {
        if (authUser) {
          if (!user?.permissionsV2) {
            await updateProfile(authUser);
          }
          tokenId.current = authUser.accessToken;
        }
      } catch (error) {
        console.error("Failed loading user", error);
        await logoutUser();
        // Most probably a connection error. Handle appropriately.
      } finally {
        setLoadingUser(false);
      }
    });

    return () => unsubscriber();
  }, []);

  // Redirect to login (this needs to be in an effect because it uses the router)
  useEffect(() => {
    const handleRedirect = async () => {
      if (
        pathname.includes("/invitation-application") &&
        loadingUser === false &&
        user === null
      ) {
        push({
          pathname: "/login",
          query: query,
        });
      } else if (
        !pathname.includes("/invitation-application") &&
        pathname.includes("/invitation") &&
        loadingUser === false &&
        user === null
      ) {
        push({
          pathname: "/login",
          query: query,
        });
      }

      if (
        loadingUser === false &&
        user === null &&
        isAuthPage === false &&
        !tokenId.current &&
        logoutLoading === false
      ) {
        console.log("Redirecting to login");
        // console.log(
        //   "Redirecting to login",
        //   loadingUser,
        //   user,
        //   isAuthPage,
        //   tokenId.current,
        //   logoutLoading
        // );
        // Redirect to next login page since most users will use next app
        if (!query?.redirectLink) {
          query.redirectLink = asPath;
        }
        push({
          pathname: "/login",
          query: query,
        });
      }
      if (
        pathname.includes("/logout") &&
        user &&
        logoutLoading === false &&
        loadingUser === false
      ) {
        await logoutUser();
      }

      if (
        pathname.includes("/login") &&
        user &&
        logoutLoading === false &&
        loadingUser === false
      ) {
        // Manage invitations
        const invitationsInStandby = user?.invitations.filter(
          (invitation) => invitation.status === "standby"
        );
        // console.log("invitationsInStandby", invitationsInStandby);
        if (invitationsInStandby.length > 0) {
          if (invitationsInStandby[0].organization) {
            push(
              `/invitation?organizationId=${invitationsInStandby[0].organization}`
            );
          } else if (invitationsInStandby[0].application) {
            push(
              `/invitation-application?applicationId=${invitationsInStandby[0].application}`
            );
          }
        }

        // Manage redirection
        if (query?.redirectLink && query?.redirectLink.charAt(0) === "/") {
          //console.log("push redirect link");
          push(query?.redirectLink);
        } else {
          //console.log("No redirect page");
          if (user?.role === "admin") {
            // console.log("push admin");
            push("/admin/applications");
          } else {
            // console.log("push non admin");
            push("/applications/page/1");
          }
        }

        // Manage banned users
        if (user?.banned === true && isAuthPage === false) {
          console.log("Banned user, log out");
        }
      }
    };
    handleRedirect();
  }, [
    asPath,
    user,
    query,
    pathname,
    loadingUser,
    isAuthPage,
    push,
    logoutLoading,
  ]);

  // Refresh token from Firebase hook
  useEffect(() => {
    const unsubscribe = auth.onIdTokenChanged(async (authUser) => {
      //console.log("authUser token changed", authUser);
      if (authUser) {
        const token = await authUser.getIdToken();
        tokenId.current = token;
        // Update user state if needed
        await updateProfile(authUser);
      } else if (auth && user && tokenId?.current) {
        const newToken = await auth.currentUser.getIdToken(true);
        tokenId.current = newToken;
      }
    });

    return () => unsubscribe();
  }, []);

  // Async setInterval to refresh token (OLD)
  // useEffect(() => {
  //   const checkLogin = setIntervalAsync(async function () {
  //     if (!isAuthPage && auth && user && tokenId?.current) {
  //       try {
  //         // Attempt to refresh the token proactively
  //         const newToken = await auth.currentUser.getIdToken(true);
  //         if (newToken !== tokenId.current) {
  //           tokenId.current = newToken;
  //           console.log("Token refreshed proactively");
  //         }
  //       } catch (error) {
  //         console.error("Error refreshing token:", error);
  //         // Only call loginCheck if token refresh fails
  //         const loginCheck = await fetch("/api/loginCheck", {
  //           headers: {
  //             Authorization: `Bearer ${tokenId.current}`,
  //             "Content-Type": "application/json",
  //           },
  //         });
  //         if (loginCheck.ok !== true) {
  //           await handleLogout();
  //         }
  //       }
  //     } else {
  //       await clearIntervalAsync(checkLogin);
  //     }
  //     //}, 30 * 1000);
  //   }, 5 * 60 * 1000); // Check every 5 minutes instead of 30 seconds

  //   setCheckLoginInterval(checkLogin);

  //   return () => {
  //     clearIntervalAsync(checkLogin);
  //     setCheckLoginInterval(null);
  //   };
  // }, [user, isAuthPage]);

  // // Add this function to handle logout
  // const handleLogout = async () => {
  //   if (checkLoginInterval) {
  //     clearInterval(checkLoginInterval);
  //   }
  //   await logoutUser();
  // };

  useEffect(() => {
    if (!isAuthPage && !logoutLoading) {
      const events = ["click", "scroll", "load", "keydown"];

      const eventHandler = () => {
        localStorage.setItem("lastInteractionTime", Date.now());
        if (timeoutId) {
          startTimer();
        }
      };

      const addEvents = () => {
        events.forEach((eventName) => {
          window.addEventListener(eventName, eventHandler);
        });

        startTimer();
      };

      const removeEvents = () => {
        events.forEach((eventName) => {
          window.removeEventListener(eventName, eventHandler);
        });
      };

      const startTimer = () => {
        if (!isAuthPage && !logoutLoading) {
          const sessionDuration = 1000 * 60 * 60 * 1; // 1h
          //const sessionDuration = 1000 * 30; // 2h
          timeoutId.current = setTimeout(() => {
            let lastInteractionTime = localStorage.getItem(
              "lastInteractionTime"
            );

            const millisecondsUntilExpiration =
              sessionDuration - (Date.now() - lastInteractionTime);
            console.log(
              "millisecondsUntilExpiration",
              millisecondsUntilExpiration,
              "for timeoutId ",
              timeoutId
            );
            console.log("logoutLoading", logoutLoading);
            if (millisecondsUntilExpiration >= 0) {
              startTimer();
            } else if (logoutLoading === false) {
              console.log("Session expired, log out");
              clearTimeout(timeoutId);
              logoutUser();
            }
          }, sessionDuration);
        }
      };

      if (user) {
        addEvents();

        return () => {
          removeEvents();
        };
      }
    }
  }, [timeoutId, push, user, logoutLoading, isAuthPage]);

  // Prevent pages from loading if not logged in (during redirect)
  if (user === null && isAuthPage === false) {
    return null;
  }

  return (
    <UserContext.Provider
      value={{
        ...user,
        loadingUser,
        updateProfile,
        accessToken: tokenId.current,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}
